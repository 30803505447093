export default {
  error_occurred:
    "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
  login: "Einloggen",
  sign_up: "Konto anlegen",
  register: "Registrierung",
  enter_tenant_name:
    "Wählen Sie das Unternehmen aus, das mit Ihrem Calendesk-Konto verknüpft ist.",
  no_account_question: "Sie haben noch kein Konto?",
  do_you_already_have_an_account: "Haben Sie bereits ein Konto?",
  login_title: "Jetzt einloggen",
  login_tenant: "Unternehmensname",
  company_field_placeholder: "z.B. Hair&More",
  next: "Weiter",
  register_subtitle: "Neues Konto erstellen",
  register_confirm_title: "Bestätigen Sie Ihre E-Mail-Adresse",
  register_confirm_subtitle:
    "Eine E-Mail mit einem Link zur Aktivierung Ihres Kontos wurde an die bei der Registrierung angegebene E-Mail-Adresse gesendet.",
  register_confirm_text:
    "Bitte aktivieren Sie Ihr Konto, um Calendesk nutzen zu können.",
  register_data_incorrect:
    "Die von Ihnen eingegebene E-Mail-Adresse ist bereits im Calendesk-System registriert. Wenn Sie Ihr Passwort vergessen haben, verwenden Sie bitte die Erinnerungsoption.",
  resend_activate_email: "Aktivierungslink erneut senden",
  login_email: "Ihre E-Mail-Adresse",
  password: "Passwort",
  email_or_password_incorrect:
    "Die angegebene E-Mail-Adresse oder das Passwort ist nicht korrekt. Wenn Sie Ihr Passwort vergessen haben, nutzen Sie die Erinnerungsoption.",
  please_activate_account:
    "Bitte aktivieren Sie Ihr Benutzerkonto. Ein Aktivierungslink wurde an Ihre E-Mail-Adresse gesendet.",
  activate_account_error_message: "Der Link ist ungültig oder abgelaufen.",
  forgotten_password: "Passwort vergessen?",
  forgotten_password_title: "Jeder kann mal sein Passwort vergessen",
  forgotten_password_text:
    "Geben Sie die E-Mail-Adresse ein, an die die Anweisungen zum Ändern des Passworts gesendet werden sollen.",
  forgotten_password_button: "Link zum Ändern des Passworts senden",
  forgotten_password_success_title: "Bitte überprüfen Sie Ihr Email-Postfach",
  forgotten_password_success_text:
    "Wenn die E-Mail-Adresse im System vorhanden ist, wurde gerade eine E-Mail mit Anweisungen zum Ändern des Passworts gesendet.",
  reset_password: "Neues Passwort setzen",
  reset_password_title: "Neues Passwort setzen",
  reset_password_text: "Bitte geben Sie Ihr neues Passwort ein.",
  reset_password_text_hint:
    "Das Passwort sollte aus mindestens 8 Zeichen bestehen, einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
  reset_password_button: "Neues Passwort speichern",
  reset_password_fail_text:
    "Passwort wurde nicht geändert. Der Link kann bereits abgelaufen sein.",
  reset_password_success_title: "Das Passwort wurde geändert.",
  reset_password_sent_email:
    "An die E-Mail-Adresse des Benutzers wurde ein Link zum Festlegen eines Passworts gesendet.",
  reset_password_success_text:
    "Sie können sich mit Ihrem neuen Passwort einloggen.",
  reset_password_success_button: "Ok",
  profile: "Profil",
  number_of_bookings: "Anzahl der Buchungen",
  number_of_new_users: "Anzahl der Neukunden",
  number_of_newsletter_members: "Anzahl der Newsletter-Abonnements",
  monthly_divided: "In Monate aufgeteilt",
  area_code: "Vorwahlnummer",
  other: "Andere",
  or: "oder",
  short_month_1: "JAN",
  short_month_2: "FEB",
  short_month_3: "MÄRZ",
  short_month_4: "APR",
  short_month_5: "MAI",
  short_month_6: "JUN",
  short_month_7: "JUL",
  short_month_8: "AUG",
  short_month_9: "SEPT",
  short_month_10: "OKT",
  short_month_11: "NOV",
  short_month_12: "DEZ",
  section_admin_panel: "Dashboard",
  section_admin_panel_clients: "Dashboard / Kunden",
  section_admin_panel_newsletters: "Dashboard / Kunden / Newsletter",
  section_admin_panel_customer_notifications:
    "Dashboard / Kunden / Benachrichtigungen",
  section_admin_panel_profile: "Dashboard / Profil",
  section_admin_panel_employees: "Dashboard / Mitarbeiter",
  section_admin_panel_sales: "Panel / Verkauf",
  section_admin_panel_settings: "Dashboard / Einstellungen",
  section_admin_panel_service: "Dashboard / Dienste",
  section_admin_panel_plugins: "Dashboard / Erweiterungen",
  section_admin_panel_payments: "Dashboard / Rechnungen und Zahlungen",
  section_admin_panel_offer: "Panel / Angebot",
  section_admin_panel_tools: "Panel / Werkzeuge",
  section_admin_panel_notifications: "Panel / Benachrichtigungen",
  simple_store: "Produkte",
  section_admin_subscriptions_list: "Dashboard / Abonnement",
  last_update: "Letzte Aktualisierung",
  add_client: "Kunden hinzufügen",
  add_newsletter_record: "E-Mail-Adresse hinzufügen",
  upload_photo: "Bild hochladen",
  upload_photo_error_message:
    "Das Hochladen des Fotos ist fehlgeschlagen. Die maximale Dateigröße sollte 10 MB betragen.",
  upload_photos_error_message:
    "Einige Bilder können möglicherweise nicht hinzugefügt werden. Die maximale Größe sollte 10 MB betragen.",
  get_company_data: "Daten herunterladen",
  get_company_data_failed: "Daten konnten nicht heruntergeladen werden.",
  company_name: "Unternehmensname",
  street_and_number: "Straße und Hausnummer",
  postcode: "Postleitzahl",
  city: "Ort",
  country: "Land",
  send_email_with_password_change:
    "Sollen wir einen Link zum Festlegen eines Passworts an Ihre E-Mail-Adresse senden?",
  client_data: "Kundendaten",
  company_business_activity: "Rechnungsdaten",
  remove_image: "Bild entfernen",
  no_way_to_undo_action: "Diese Aktion kann nicht rückgängig gemacht werden!",
  remove_image_warning:
    "Sind Sie sicher, dass Sie das Bild des Nutzers löschen möchten?",
  add_booking: "Buchung hinzufügen",
  add_unavailable_booking_slot: "Kalendersperre hinzufügen",
  add_tag: "Etikett hinzufügen",
  duplicate: "Duplizieren",
  add_to_group: "Zur Gruppe hinzufügen",
  cancel_booking: "Buchung stornieren",
  delete_booking: "Buchung stornieren",
  delete_booking_failed: "Die ausgewählte Buchung konnte nicht gelöscht werden",
  cancel_booking_failed:
    "Die ausgewählte Buchung konnte nicht storniert werden",
  selected_n_positions: "@{amount} Artikel sind ausgewählt",
  remove_multiple_users: "Benutzer löschen",
  remove_multiple_employees: "Mitarbeiter löschen",
  remove_employee: "Mitarbeiter löschen",
  lock_multiple_users: "Benutzer sperren",
  unlock_multiple_users: "Benutzer entsperren",
  lock_multiple_employees: "Mitarbeiter sperren",
  unlock_multiple_employees: "Mitarbeiter entsperren",
  lock_employee: "Mitarbeiter sperren",
  unlock_employee: "Mitarbeiter entsperren",
  user_remove_failed_bookings:
    "Der Benutzer hat geplante Besuche oder aktive Abonnements. Um sie zu entfernen, ist eine Stornierung erforderlich.",
  employee_remove_failed_bookings_or_services:
    "Der Mitarbeiter hat geplante Besuche oder wurde bereits entfernt. Vor der Entfernung des Mitarbeiters müssen Sie seine Besuche stornieren.",
  operation_failed:
    "Beim Vornehmen der Änderungen ist ein Fehler aufgetreten. Bitte erneut versuchen.",
  accounts_update_succeeded:
    "Die ausgewählten Benutzerkonten wurden erfolgreich aktualisiert",
  accounts_update_failed:
    "Ausgewählte Konten konnten nicht aktualisiert werden",
  remove_account_warning:
    "Sind Sie sicher, dass Sie das Benutzerkonto löschen wollen?",
  remove_accounts_warning:
    "Sind Sie sicher, dass Sie die ausgewählten Benutzerkonten löschen wollen?",
  add_group: "Gruppe hinzufügen",
  sort_by: "Sortieren",
  basic_data: "Basisdaten",
  public_data: "Füllen Sie zusätzliche Informationen über den Mitarbeiter aus",
  remove_category: "Kategorie löschen",
  remove_category_warning:
    "Sind Sie sicher, dass Sie die Kategorie löschen wollen?",
  services_in_category: "Dienste in der Kategorie",
  services_already_assigned_in_category:
    "Es gibt Dienstleistungen, die der Kategorie zugeordnet sind. Ordnen Sie die Dienstleistungen einer anderen Kategorie zu, um fortzufahren.",
  assign_service_to_category: "Ordnen Sie Dienste einer Kategorie zu",
  current_category: "Aktuelle Kategorie",
  services_assign_failed:
    "Beim Zuordnen der ausgewählten Dienste zu Kategorien ist ein Fehler aufgetreten",
  assign_n_services_to_category: "@{n} Dienste der Kategorie zuweisen:",
  category_order_info:
    "Die Reihenfolge, in der Sie die Kategorien und Dienstleistungen einrichten, wird auf der Website und der mobilen App Ihrer Kunden angezeigt.",
  lock_account: "Konto sperren",
  remove_account: "Konto löschen",
  fetch_data_failed: "Beim Herunterladen der Daten ist ein Fehler aufgetreten",
  edit_service: "Dienst bearbeiten",
  remove_service: "Dienst löschen",
  variants: "Varianten",
  booking_settings: "Buchungseinstellungen",
  booking_time_before: "Reservierung den Service mindestens",
  cancel_time_before: "Stornierung den Service bis spätestens",
  change_time_before: "Umbuchung den Service bis spätestens",
  before_visit: "vor dem Besuch",
  slots_every_checkbox_text:
    "Zeigen Sie den Kunden die Möglichkeit, zu buchen alle",
  break_time_before_checkbox_text:
    "Zusätzliche Pausenzeit vor der erstellten Buchung",
  break_time_after_checkbox_text:
    "Zusätzliche Pausenzeit nach Erstellung einer Buchung",
  resources_info:
    "Wählen Sie die begrenzten Ressourcen aus, die für Dienstleistungen benötigt werden. Die Kunden können keinen Termin online buchen, wenn die Ressourcen für andere Termine erschöpft sind.",
  quantity: "Anzahl",
  quantity_pcs: "Anzahl (Stk.)",
  value_is_too_high:
    "Der eingegebene Wert ist zu groß. Der Maximalwert ist: @{number}.",
  value_is_too_low:
    "Der eingegebene Wert ist zu klein. Der Mindestwert ist: @{number}.",
  resource_name: "Ressourcenname",
  add_variant: "Dienstleistungsvariante hinzufügen",
  service_active: "Dienstleistung im Kalender der Kunden aktiv",
  category_active_short: "Aktiv",
  category_inactive_short: "Inaktiv",
  variant_name: "Name der Variante",
  service_blocked: "Dienst gesperrt",
  all_employees: "Alle Mitarbeiter",
  price_in_currency: "Preis in @{currency}",
  no_employees_found:
    "Es wurden keine Mitarbeiter hinzugefügt. Sie können jetzt einen Mitarbeiter anlegen und ihn diesen Dienstleistungen zuordnen. Ihre Kunden können die zusätzlichen Mitarbeiter auswählen, die für diese Dienstleistungen eingesetzt werden.",
  add_employee_fail:
    "Beim Hinzufügen eines Mitarbeiters ist ein Problem aufgetreten. Bitte erneut versuchen.",
  select_employee_error: "Mindestens einen Mitarbeiter auswählen",
  service_update_failed:
    "Dienstaktualisierung fehlgeschlagen. Bitte erneut versuchen.",
  service_creation_failed:
    "Dienstleistungen konnten nicht erstellt werden. Bitte versuchen Sie es erneut.",
  remove_service_warning:
    "Sind Sie sicher, dass Sie den Dienst entfernen möchten?",
  remove_service_failed:
    "Löschen des Dienstes fehlgeschlagen. Bitte erneut versuchen.",
  move: "Verschieben",
  tag_visits: "Besuche mit einem Etikett",
  services_with_resource: "Dienste mit Ressource",
  resource: "Ressource",
  users_in_group: "Kunden in der Gruppe",
  remove_tag_warning:
    "Sind Sie sicher, dass Sie das Tag „@{tag}” entfernen möchten?",
  remove_tag: "Etikett entfernen",
  edit_tag: "Etikett bearbeiten",
  remove_resource_warning:
    "Sind Sie sicher, dass Sie die Ressource „@{resource}“ löschen möchten?",
  remove_resource: "Ressource löschen",
  edit_resource: "Ressource bearbeiten",
  remove_group_warning:
    "Sind Sie sicher, dass Sie die Gruppe „@{group}” löschen möchten?",
  remove_group: "Gruppe löschen",
  edit_group: "Gruppe bearbeiten",
  tag_info:
    "Etiketten beschreiben die Merkmale eines Kundenbesuchs. Jeder Besuch kann mit mehreren Etiketten versehen werden.",
  resource_info:
    "Die Ressourcen definieren die Räume oder die Geräte, die für die Durchführung der Dienstleistung benötigt werden. Z. B. Stuhl, Zimmer, Massagetisch usw.",
  resource_info_2:
    "Durch die Zuweisung von Ressourcen für bestimmte Dienstleistungen wird das Risiko eines Problems minimiert - z. B. fehlende Arbeitsmittel, um einen Kunden zu bedienen.",
  group_info:
    "Gruppen sind hilfreich bei der Verwaltung von Kunden. Jeder Kunde kann mehreren Gruppen zugeordnet werden.",
  email_notifications: "E-Mail Benachrichtigungen",
  sms_notifications: "SMS-Benachrichtigungen",
  buy_more: "Kaufen",
  notifications_count: "Anzahl der Benachrichtigungen",
  pay: "Bezahlen",
  notification_first_label: "Ist geblieben",
  notification_second_label_email: "e-Mail Benachrichtigungen",
  notification_second_label_sms: "SMS-Benachrichtigungen",
  notifications_sms_email: "SMS- und E-Mail-Benachrichtigungen",
  notifications_reminder_first:
    "Benachrichtigungen erinnern Sie und Ihre Kunden an anstehende Meetings.",
  notifications_reminder_second:
    "Entscheiden Sie, wer und welche Art von Benachrichtigungen informiert werden soll.",
  notification: "Benachrichtigung",
  notify_tenant: "Mitarbeiter",
  notify_client: "Kunde",
  notification_save_success:
    "Die Benachrichtigungen wurden korrekt aktualisiert.",
  invoice_data: "Rechnungsdaten",
  service_categories: "Dienstleistungskategorien",
  application: "App",
  email: "E-Mail",
  currency: "EUR",
  category: "Kategorie",
  categories: "Kategorien",
  service: "Dienstleistung",
  service_list: "Liste der Dienstleistungen",
  services: "Dienste",
  employee: "Mitarbeiter",
  employees: "Mitarbeiter",
  employees_list: "Mitarbeiterliste",
  plugins: "Erweiterungen",
  packages: "Ihre Pakete",
  plugins_mobile_drafts: "Mobile-App",
  plugins_mobile_editor: "Mobiler App-Editor",
  plugins_payments_tpay: "Tpay-Zahlungen",
  plugins_payments_stripe: "Stripe-Zahlungen",
  settings_platform: "Plattform",
  notifications: "Benachrichtigungen",
  profile_company: "Ihr Unternehmen",
  profile_account: "Ihr Profil",
  payments: "Rechnungen und Zahlungen",
  settings: "Einstellungen",
  duration: "Dauer",
  from: "Von",
  to: "Bis",
  filter: "Filtern",
  close: "Schließen",
  today: "Heute",
  tomorrow: "Morgen",
  this_week: "Diese Woche",
  this_month: "Dieser Monat",
  this_year: "Dieses Jahr",
  yesterday: "Gestern",
  last_week: "Letzte Woche",
  last_month: "Letzter Monat",
  last_year: "Letztes Jahr",
  clear: "Löschen",
  service_name: "Name der Dienstleistung",
  category_name: "Kategoriename",
  price: "Preis",
  name: "Vorname",
  surname: "Nachname",
  from_cannot_be_greater_than_to:
    'Die "Von"-Stunde darf nicht größer sein als die "Bis"-Stunde',
  name_and_surname: "Vorname und Nachname",
  phone: "Telefon",
  field_is_required: "Das Feld ist erforderlich.",
  value_is_no_url: "Der eingegebene Wert ist keine gültige URL.",
  value_is_no_url_to_facebook:
    "Der eingegebene Wert ist keine gültige Facebook-Profil-URL.",
  value_is_no_url_to_twitter:
    "Der eingegebene Wert ist keine gültige Twitter-Profil-URL.",
  value_is_no_url_to_instagram:
    "Der eingegebene Wert ist keine gültige Instagram-Profil-URL.",
  value_is_no_url_to_youtube:
    "Der eingegebene Wert ist keine gültige YouTube-Profil-URL.",
  value_is_no_domain: "Der eingegebene Wert ist keine Domäne.",
  min_n_characters: "Die Mindestanzahl an Zeichen ist @{min}.",
  max_n_characters: "Die maximale Anzahl von Zeichen ist @{max}.",
  email_is_incorrect: "Die E-Mail-Adresse ist nicht korrekt.",
  page_not_found: "Ups, da ist etwas schief gelaufen. Seite nicht gefunden",
  go_back_to_main_page:
    "Bitte gehen Sie zurück zur Startseite und versuchen Sie es erneut.",
  logout: "Abmelden",
  status: "Status",
  status_approved: "Akzeptiert",
  status_waiting: "Ausstehend",
  status_payment: "Zu bezahlen",
  status_cancelled: "Storniert",
  status_done: "Hat stattgefunden",
  date: "Datum",
  cancel: "Stornieren",
  yes: "Ja",
  no: "Nein",
  date_from: "Datum von",
  date_to: "Datum bis",
  nothing_found_here: "Keine Ergebnisse",
  nothing_here: "Kein",
  save: "Speichern",
  details: "Details",
  add_image: "Bild hinzufügen",
  change: "Ändern",
  remove: "Löschen",
  email_is_taken:
    "Das Konto mit der angegebenen E-Mail-Adresse wird bereits verwendet.",
  dashboard: "Dashboard",
  users: "Kunden",
  users_list: "Kundenliste",
  calendar: "Kalender",
  add: "Hinzufügen",
  resources: "Ressourcen",
  translations: "Übersetzungen",
  web_editor: "Website-Editor",
  websites: "Webseiten",
  mobile_app_title: "Mobile Anwendung",
  preview: "Vorschau",
  roles: "Funktion",
  role_admin: "Administrator",
  role_manager: "Manager",
  role_employee: "Mitarbeiter (vertrauenswürdig)",
  role_employee_basic: "Mitarbeiter (eingeschränkt)",
  permissions: "Berechtigungen",
  admin_access_question:
    "Der Mitarbeiter kann sich im Calendesk-Panel anmelden",
  send_employees_app_link:
    "Senden Sie einen Link zur mobilen App für Mitarbeiter per E-Mail",
  search: "Suchen",
  add_user: "Kunde hinzufügen",
  add_employee: "Mitarbeiter hinzufügen",
  add_category: "Kategorie hinzufügen",
  edit_category: "Kategorie bearbeiten",
  add_service: "Dienstleistung hinzufügen",
  add_resource: "Ressource hinzufügen",
  bookings_today: "Buchungen für heute",
  bookings_tomorrow: "Buchungen für morgen",
  bookings_this_week: "Buchungen für diese Woche",
  newsletter: "Newsletter",
  month_1: "Januar",
  month_2: "Februar",
  month_3: "März",
  month_4: "April",
  month_5: "Mai",
  month_6: "Juni",
  month_7: "Juli",
  month_8: "August",
  month_9: "September",
  month_10: "Oktober",
  month_11: "November",
  month_12: "Dezember",
  monday: "Montag",
  tuesday: "Dienstag",
  wednesday: "Mittwoch",
  thursday: "Donnerstag",
  friday: "Freitag",
  saturday: "Samstag",
  sunday: "Sonntag",
  short_day_monday: "Mo.",
  short_day_tuesday: "Di.",
  short_day_wednesday: "Mi.",
  short_day_thursday: "Do.",
  short_day_friday: "Fr.",
  short_day_saturday: "Sa.",
  short_day_sunday: "So.",
  refresh: "Aktualisieren",
  select_date: "Datum wählen",
  edit: "Bearbeiten",
  ok: "Ok",
  client: "Kunde",
  clients: "Kunden",
  tag: "Etikett",
  added_tag: "Etikett wurde hinzugefügt",
  added_group: "Gruppe wurde hinzugefügt",
  added_resource: "Die Ressource wurde hinzugefügt",
  tags: "Etiketten",
  phone_is_invalid: "Die Telefonnummer ist nicht korrekt.",
  birthday: "Geburtstag",
  send_reset_password:
    "Sollen wir einen Link zum Festlegen eines Passworts an Ihre E-Mail-Adresse senden?",
  user_created_successfully: "Der Kunde wurde erfolgreich erstellt.",
  employee_created_successfully: "Der Mitarbeiter wurde korrekt erstellt.",
  description: "Beschreibung",
  created_calendar_event_correctly: "Kalendereintrag hinzugefügt.",
  updated_calendar_event_correctly: "Kalendereintrag aktualisiert.",
  updated_multiple_bookings_correctly: "Buchungen aktualisiert.",
  deleted_multiple_bookings_correctly: "Buchung entfernt.",
  deleted_multiple_unavailable_booking_slots_correctly:
    "Kalendersperren entfernt.",
  deleted_service_event_correctly: "Dienst wurde gelöscht.",
  creating_calendar_event_failed:
    "Eintrag konnte nicht hinzugefügt werden, bitte erneut versuchen.",
  deleting_service_event_failed:
    "Die Löschung ist fehlgeschlagen. Prüfen Sie, ob Sie Termine für diese Dienstleistungen geplant haben, und versuchen Sie es erneut.",
  updating_calendar_event_failed:
    "Eintragsaktualisierung fehlgeschlagen, bitte versuchen Sie es erneut.",
  updating_bookings_failed:
    "Fehler beim Aktualisieren Ihrer Buchung, erneut versuchen.",
  deleting_bookings_failed:
    "Löschen der Buchung fehlgeschlagen, bitte erneut versuchen.",
  block_spot:
    "Ein Ereignis, das nicht mit dem Besuch des Kunden zusammenhängt (Kalendersperre für Kunden)",
  block: "Sperrung",
  unavailable_spot: "Gesperrt",
  id: "ID",
  per_page: "Elemente pro Seite",
  groups: "Gruppen",
  group: "Gruppe",
  delete: "Löschen",
  facebook: "Link zum Facebook-Profil",
  instagram: "Link zum Facebook-Profil",
  youtube: "Link zum YouTube-Profil",
  twitter: "Link zum Twitter-Profil",
  active_accounts: "Aktive Konten",
  inactive_accounts: "Inaktive Konten",
  active_account: "Aktives Konto",
  inactive_account: "Inaktives Konto",
  visit: "Besuch",
  loading: "Wird geladen...",
  created_at: "Erstellt",
  canceled_at: "Storniert",
  user: "Benutzer",
  category_active: "Dienstleistungskategorie sichtbar für Kunden",
  category_inactive: "Dienstleistungskategorie für Kunden nicht sichtbar",
  email_verified_at: "E-Mail-Verifizierung",
  email_unverified: "E-Mail nicht verifiziert",
  gender: "Geschlecht",
  gender_male: "Mann",
  gender_female: "Frau",
  gender_other: "Anderes",
  tax: "MwSt/USt",
  tax_prefix: "Vorwahl",
  block_account: "Konto sperren",
  unblock_account: "Konto entsperren",
  generate_new_password_automatically:
    "Link zum Ändern des Passworts an die E-Mail-Adresse senden.",
  unblock_account_question:
    "Sind Sie sicher, dass Sie Ihr Konto entsperren möchten?",
  block_account_question:
    "Sind Sie sicher, dass Sie das Konto sperren möchten?",
  delete_user: "Konto löschen",
  delete_users: "Konten löschen",
  user_can_not_be_loaded:
    "Fehler aufgetreten. Der Benutzer kann nicht geladen werden.",
  user_bookings: "Buchungen",
  user_newsletter: "Newsletter",
  title: "Titel",
  color: "Farbe",
  no_employee_selected_in_calendar:
    "Es wurden keine Mitarbeiter ausgewählt. Wählen Sie sie aus der obigen Liste aus, um den Kalender anzuzeigen.",
  client_policy_confirmation_text:
    "Der Kunde bestätigt, dass er die Bestimmungen und die Datenschutzerklärung gelesen hat.",
  /*
  calendar toolbar translations
   */
  listWeek: "Tagesplan",
  resourceTimeGridDay: "Tag",
  timeGridWeek: "Woche",
  dayGridMonth: "Monat",
  current_week: "Aktuelle Woche",
  current_month: "Aktueller Monat",
  /*
  end of calendar toolbar translations
   */
  date_day_from: "Von (Tag)",
  date_hour_from: "Ab (Stunde)",
  date_day_to: "Bis (Tag)",
  date_hour_to: "Bis (Stunde)",
  created_date_range: "Erstellungsdatum",
  canceled_date_range: "Stornierungsdatum",
  event_date_range: "Veranstaltungsdatum",
  payment_date_range: "Zahlungsdatum",
  you_can_add_note_here: "Hier können Sie eine private Notiz hinzufügen",
  account_activation_resend_link_succeeded:
    "Der Aktivierungslink wurde erneut versendet",
  account_activation_resend_link_failed:
    "Beim Generieren des Links ist ein Fehler aufgetreten. Bitte erneut versuchen",
  calendar_settings: "Kalendereinstellungen",
  minimal_length_of_calendar_event: "Minimale Länge des Kalenderereignisses",
  hour_divider_interval: "Stundenmarkierungen sichtbar alle",
  calendar_time_boundaries: "Kalenderumfang",
  all_bookings: "Alle Buchungen",
  bookings: "Buchungen",
  more: "mehr",
  visit_datetime: "Buchungsfrist",
  client_names: "Vor- und Nachname des Kunden",
  waiting: "Genehmigung ausstehend",
  payment: "Vorauszahlung erforderlich",
  approved: "Akzeptiert",
  cancelled: "Storniert",
  done: "Beendet",
  delete_succeeded: "Erfolgreich gelöscht",
  delete_failed: "Beim Löschen ist ein Fehler aufgetreten",
  confirmation_modal_header: "Die Aktion muss bestätigt werden",
  confirmation_modal_warning: "Sind Sie sicher, dass Sie fortfahren möchten?",
  change_status: "Status ändern",
  remove_n: "Löschen (@{n})",
  remove_event: "Ereignis löschen",
  editor_tool_global_settings_title: "Allgemeine Einstellungen",
  editor_tool_global_settings_subtitle: "Farbe und Logo",
  calendesk_file_input_first: "Dateien hier ablegen oder ",
  calendesk_file_input_second: "von der Festplatte auswählen,",
  calendesk_file_input_third: "zum Hinzufügen",
  assets: "Dateien",
  domain_configuration_title: "Domänenkonfiguration",
  domain_configuration_subtitle:
    "Wählen Sie die Domain aus, unter der Ihre Website erreichbar sein soll",
  domain_configuration_calendesk_domain:
    'Geben Sie den Namen der Subdomain (Alias) ein, unter der Ihre Website verfügbar sein soll, z. B. "IhreFirma.calendesk.com".',
  domain_configuration_calendesk_domain_2:
    'Geben Sie den Namen der Subdomain (Alias) ein, unter der Ihre Website verfügbar sein soll, z. B. "ihrefirma.calendesk.net".',
  domain_configuration_own_domain_text:
    'Sie können auch eine eigene Domain hinzufügen, zB "ihrefirma.de".',
  domain_configuration_own_domain_add_button: "Eigene Domäne hinzufügen",
  domain_configuration_own_domain_edit_button: "Eigene Domain bearbeiten",
  domain_configuration_footer:
    "Ihre Website wird unter: @{domain} verfügbar sein",
  domain_configuration_form_alias: "Subdomain (Alias) z. B. Ihr Firmenname",
  domain_configuration_form_domain: "Domäne",
  add_domain_title: "Eigene Domäne hinzufügen",
  add_domain_verification_error:
    "Die Domäne wird verifiziert. Wenn alles korrekt eingerichtet wurde, warten Sie 15 Minuten und versuchen Sie es erneut.",
  add_domain_verification_success: "Die Domäne funktioniert einwandfrei",
  add_domain_first_step_title: "Ihre Domäne",
  add_domain_first_step_subtitle:
    "Geben Sie den Domainnamen ein, den Sie mit Ihrer Website verknüpfen möchten",
  add_domain_first_step_domain: "Ihre Domäne",
  add_domain_first_step_confirm: "Fortsetzen",
  add_domain_second_step_title: "Domänenanbieter-Panel",
  add_domain_second_step_subtitle:
    "Loggen Sie sich in das Panel Ihres Domänenanbieters ein, suchen Sie den Abschnitt DNS-Einstellungen und fügen Sie zwei neue Einträge hinzu - wie im nächsten Schritt beschrieben.",
  add_domain_second_step_confirm: "Fortsetzen",
  add_domain_third_step_title: "Neue Datensätze hinzufügen",
  add_domain_third_step_subtitle:
    "Fügen Sie Domäneneinträge hinzu - wie unten. Bei einigen Domänenanbietern kann der CNAME-Eintrag mit einem Punkt enden.",
  add_domain_third_step_subtitle_dot: "mit einem Punkt am Ende",
  add_domain_third_step_confirm: "Fortsetzen",
  add_domain_third_step_dns_name: "Name",
  add_domain_third_step_dns_type: "Typ",
  add_domain_third_step_dns_url: "Adresse",
  add_domain_fourth_step_title: "Überprüfung der Leistung",
  add_domain_fourth_step_subtitle:
    "Nachdem die Domäne korrekt eingerichtet wurde, überprüfen Sie die Änderungen.",
  add_domain_fourth_step_confirm: "Überprüfen Sie die Funktion der Domäne",
  web_global_logo: "Logotyp",
  web_global_primary_color: "Hauptfarbe",
  gallery_input_set_image: "Einfügen",
  no_selected_images: "Es wurden keine Fotos ausgewählt",
  home_about_element_gallery: "Bilder in der Galerie",
  new_website: "Neue Website",
  drafts_table_header_title: "Name der Vorlage",
  drafts_table_header_last_updated: "Letzte Aktualisierung",
  published_text: "Veröffentlicht",
  not_published_text: "Unveröffentlicht",
  disabled: "Deaktiviert",
  publish: "Veröffentlichen",
  drafts_category_1: "Beauty",
  drafts_category_2: "Fitness",
  drafts_create_website_button: "Website erstellen",
  tool_save_draft: "Projekt speichern",
  drafts_disable_confirm_dialog_title: "Deaktivieren Sie die Website",
  drafts_disable_confirm_dialog_warning:
    "Sind Sie sicher, dass Sie die Webseite deaktivieren möchten?",
  drafts_disable_confirm_dialog_inportant_information:
    "Nach der Deaktivierung wird Ihre Webseite nicht mehr online verfügbar sein.",
  drafts_remove_confirm_dialog_title: "Projekt löschen",
  drafts_remove_confirm_dialog_warning:
    "Sind Sie sicher, dass Sie das Projekt löschen wollen?",
  domain_dialog_title: "Sind Sie sicher, dass Sie das Projekt löschen möchten",
  domain_dialog_button: "Domäne konfigurieren",
  domain_dialog_domain_text: "Ihre Website wird angezeigt unter:",
  editor_domain_error_message: "Die ausgewählte Domäne ist nicht verfügbar",
  publish_dialog_button: "Zurück zu den Projekten",
  /*
  ASSETS
   */
  files: "Dateien",
  assets_no_data: "Keine Fotos beigefügt",
  assets_dialog_use_selected_file: "Ausgewählte Datei verwenden",
  assets_dialog_use_selected_files: "Ausgewählte Dateien verwenden",
  remove_asset: "Datei löschen",
  remove_asset_warning: "Sind Sie sicher, dass Sie „@{file}” löschen möchten?",
  change_name_asset_title: "Datei „@{file}” umbenennen",
  change_name_asset_new_name: "Neuer Dateiname",
  editor_image: "Foto Editor",
  editor_image_confirm_button: "Anwenden",
  editor_image_crop_any: "Beliebig",
  editor_image_crop_5_4: "5:4",
  editor_image_crop_7_5: "7:5",
  editor_image_crop_16_9: "16:9",
  editor_image_crop_3_2: "3:2",
  editor_image_crop_square: "Quadrat",
  editor_image_reset: "Zurücksetzen",
  editor_image_rotate_left: "90° gegen den Uhrzeigersinn drehen",
  editor_image_rotate_right: "90° Drehung im Uhrzeigersinn",
  editor_image_flip_x: "Spiegelung an der X-Achse",
  editor_image_flip_y: "Spiegelung an der Y-Achse",
  /*
  Global
   */
  form_is_invalid:
    "Das Formular enthält Fehler. Überprüfen Sie die markierten Felder und versuchen Sie es erneut.",
  /*
  VALIDATIONS
   */
  no_integer: "Numerisches Zeichen erforderlich.",
  no_numeric_character: "Es gibt kein numerisches Zeichen.",
  no_lowercase_character: "Ein Kleinbuchstabe fehlt.",
  no_uppercase_character: "Ein Großbuchstabe fehlt.",
  no_special_character: "Ein Sonderzeichen fehlt.",
  /*
  SETTINGS PLATFORM
   */
  settings_platform_submit_button: "Änderungen anwenden",
  c_language_select_label: "Sprache",
  c_currency_select_label: "Währung",
  c_time_zone_autocomplete_label: "Zeitzone",
  c_language_select_czech: "Tschechisch",
  c_language_select_german: "Deutsch",
  c_language_select_english: "Englisch",
  c_language_select_spanish: "Spanisch",
  c_language_select_french: "Französisch",
  c_language_select_hindi: "Hinduistisch",
  c_language_select_dutch: "Niederländisch",
  c_language_select_polish: "Polnisch",
  c_language_select_portuguese: "Portugiesisch",
  c_language_select_russian: "Russisch",
  c_language_select_slovak: "Slowakisch",
  c_language_select_ukrainian: "Ukrainisch",
  c_language_select_bulgarian: "Bulgarisch",
  c_language_select_greek: "Griechisch",
  c_language_select_estonian: "Estnisch",
  c_language_select_finnish: "Finnisch",
  c_language_select_irish: "Irisch",
  c_language_select_croatian: "Kroatisch",
  c_language_select_hungarian: "Ungarisch",
  c_language_select_lithuanian: "Litauisch",
  c_language_select_latvian: "Lettisch",
  c_language_select_maltese: "Maltesisch",
  c_language_select_slovenian: "Slowenisch",
  c_language_select_albanian: "Albanisch",
  c_language_select_swedish: "Schwedisch",
  c_currency_select_pln: "Polnischer Zloty (PLN)",
  c_currency_select_eur: "Euro (EUR)",
  c_currency_select_usd: "US Dollar (USD)",
  c_currency_select_gbp: "Britisches Pfund (GBP)",
  c_currency_select_jpy: "Japanischer Yen (JPY)",
  c_currency_select_aud: "Australischer Dollar (AUD)",
  c_currency_select_cad: "Kanadischer Dollar (CAD)",
  c_currency_select_chf: "Schweizer Franken (CHF)",
  c_currency_select_sek: "Schwedische Krone (SEK)",
  c_currency_select_nzd: "Neuseeländischer Dollar (NZD)",
  c_currency_select_brl: "Brasilianischer Real (BRL)",
  c_currency_select_bgn: "Bulgarischer Lew (BGN)",
  c_currency_select_czk: "Tschechische Krone (CZK)",
  c_currency_select_dkk: "Dänische Krone (DKK)",
  c_currency_select_hkd: "Hongkong Dollar (HKD)",
  c_currency_select_huf: "Ungarischer Forint (HUF)",
  c_currency_select_ils: "Neuer Israelischer Schekel (ILS)",
  c_currency_select_myr: "Malaysischer Ringgit (MYR)",
  c_currency_select_mxn: "Mexikanischer Peso (MXN)",
  c_currency_select_twd: "Neuer Taiwan Dollar (TWD)",
  c_currency_select_nok: "Norwegische Krone (NOK)",
  c_currency_select_php: "Philippinische Peso (PHP)",
  c_currency_select_ron: "Rumänischer Leu (RON)",
  c_currency_select_rub: "Russischer Rubel (RUB)",
  c_currency_select_sgd: "Singapur-Dollar (SGD)",
  c_currency_select_thb: "Thailändischer Baht (THB)",
  c_currency_select_uah: "Ukrainische Hrywnja (UAH)",
  c_currency_select_inr: "Indische Rupie (INR)",
  c_currency_select_clp: "Chilenischer Peso (CLP)",
  c_currency_select_zar: "Südafrikanischer Rand (ZAR)",
  c_currency_select_bif: "Burundi-Franc (BIF)",
  c_currency_select_djf: "Dschibuti-Franc (DJF)",
  c_currency_select_gnf: "Guinea-Franc (GNF)",
  c_currency_select_kmf: "Komoren-Franc (KMF)",
  c_currency_select_krw: "Südkoreanischer Won (KRW)",
  c_currency_select_mga: "Madagaskar-Ariary (MGA)",
  c_currency_select_pyg: "Paraguayischer Guarani (PYG)",
  c_currency_select_rwf: "Ruanda-Franc (RWF)",
  c_currency_select_ugx: "Ugandischer Schilling (UGX)",
  c_currency_select_vnd: "Vietnamesischer Dong (VND)",
  c_currency_select_vuv: "Vanuatu-Vatu (VUV)",
  c_currency_select_xaf: "CFA-Franc BEAC (XAF)",
  c_currency_select_xof: "CFA-Franc BCEAO (XOF)",
  c_currency_select_xpf: "CFP-Franc (XPF)",
  /*
  PROFILE COMPANY
   */
  profile_company_name_label_input: "Unternehmensname",
  profile_company_name_placeholder_input: "z.B. Hair&More",
  profile_company_contact_header: "Kontaktdaten",
  profile_company_contact_address_label_autocomplete: "Adresse",
  profile_company_contact_email_label_input: "E-Mail-Adresse",
  profile_company_contact_country_autocomplete: "Land",
  profile_company_work_hours_header: "Arbeitstage und -stunden",
  profile_company_social_media_header: "Social Media",
  profile_company_social_media_facebook_label_input: "Link zum Facebook-Profil",
  profile_company_social_media_twitter_label_input: "Link zum Twitter-Profil",
  profile_company_social_media_instagram_label_input:
    "Link zum Instagram-Profil",
  profile_company_social_media_youtube_label_input: "Link zum YouTube-Profil",
  profile_company_regulations_header: "Allgemeine Geschäftsbedingungen",
  profile_company_regulations_label_textarea: "Allgemeine Geschäftsbedingungen",
  profile_company_privacy_policy_header: "Datenschutzerklärung",
  profile_company_privacy_policy_label_textarea: "Datenschutzerklärung",
  profile_company_contact_street_label_input: "Straße und Hausnummer",
  profile_company_contact_zip_code_label_input: "Postleitzahl",
  profile_company_contact_city_label_input: "Stadt",
  plugins_store_all_plugins_tab: "Alle Erweiterungen",
  plugins_mobile_drafts_header_button: "Mobile-App bearbeiten",
  create_mobile_draft_dialog_title: "Vorlage auswählen",
  create_mobile_draft_dialog_name_label_input: "Name der Vorlage",
  create_mobile_draft_dialog_name_label_input_placeholder: "z. B. Meine App",
  create_mobile_draft_dialog_select_category: "Kategorie",
  create_mobile_draft_dialog_submit: "Speichern und fortfahren",
  plugins_mobile_drafts_new_application_button: "Neue Mobile-App",
  plugins_mobile_drafts_disable_confirm_dialog_title:
    "Schalten Sie die mobile-App aus",
  plugins_mobile_drafts_disable_confirm_dialog_warning:
    "Sind Sie sicher, dass Sie die mobile-App deaktivieren möchten?",
  plugins_mobile_drafts_disable_confirm_dialog_important_information:
    "Nach der Deaktivierung wird Ihre Anwendung nicht mehr online verfügbar sein.",
  plugins_mobile_drafts_remove_confirm_dialog_title: "Projekt löschen",
  plugins_mobile_drafts_remove_confirm_dialog_warning:
    "Sind Sie sicher, dass Sie das Projekt löschen möchten?",
  plugins_mobile_preview_information_title:
    "Vorschau der Änderungen auf dem Telefon",
  plugins_mobile_preview_information_paragraph_1:
    "Veröffentlichen Sie die Vorlage. Laden Sie die Calendesk-App herunter und geben Sie auf dem ersten Bildschirm einen Namen ein",
  plugins_mobile_preview_information_paragraph_2:
    "als Firmenidentifikation. Melden Sie sich dann mit Ihrer E-Mail-Adresse und Ihrem Passwort in Ihrem Benutzerkonto an.",
  plugins_mobile_preview_information_paragraph_3:
    "Wenn Sie sich anmelden, wird die App nach den Änderungen, die Sie hier vorgenommen haben, automatisch aktualisiert.",
  plugins_mobile_preview_text_button: "Link zur App senden",
  send_mobile_app_link_dialog_title: "Link zur App senden",
  send_mobile_app_link_dialog_email_tab_title: "E-Mail",
  send_mobile_app_link_dialog_email_tab_input_label:
    "E-Mail-Adresse, an die wir den Link zur App senden sollen",
  send_mobile_app_link_dialog_email_tab_submit_button: "E-Mail senden",
  send_mobile_app_link_dialog_sms_tab_title: "SMS",
  send_mobile_app_link_dialog_sms_tab_input_label:
    "Telefonnummer, an die wir den Link zur App senden sollen",
  send_mobile_app_link_dialog_sms_tab_submit_button: "SMS senden",
  publish_mobile_draft_dialog_title: "Die mobile-App wird veröffentlicht",
  publish_mobile_draft_dialog_subtitle:
    "Es kann einige Minuten dauern, bis die App im Web angezeigt wird.",
  publish_mobile_draft_dialog_button: "Gehen Sie zurück zu Projekten",
  mobile_home_form_title: "Hauptansicht",
  mobile_home_form_logo_label: "Hintergrundbild",
  mobile_home_form_logo_select_button: "Auswählen",
  mobile_home_form_slogan_label: "Überschrift",
  mobile_home_form_signup_button: "Registrieren-Schaltfläche",
  mobile_services_form_title: "Dienste",
  mobile_services_form_menu_services_label: "Lesezeichen",
  mobile_services_form_services_title: "Überschrift",
  mobile_services_form_services_input_label: "Suchmaschine",
  mobile_services_form_services_search_empty_screen_subtitle_label:
    "Keine Ergebnisse",
  mobile_services_form_services_types_title_label: "Dienstleistungsvarianten",
  mobile_services_form_cancel_booking_success_button_label:
    "Andere - Schaltfläche",
  mobile_services_form_logo_label: "Hintergrundbild",
  mobile_services_form_logo_select_button: "Auswählen",
  mobile_about_us_form_title: "Über uns",
  mobile_about_us_form_location_title: "Standort-Kopfzeile",
  mobile_about_us_form_location_info:
    "Sie können die Adresse im Hauptfenster von Calendesk unter Einstellungen / Ihr Unternehmen festlegen. Wenn Sie die Adresse löschen, wird sie auch in der App ausgeblendet.",
  mobile_about_us_form_location_2_info:
    "Vervollständigen Sie die Adresse im Hauptfenster von Calendesk unter Einstellungen / Ihr Unternehmen, um den Standort Ihres Unternehmens in diesem Abschnitt anzuzeigen.",
  mobile_about_us_form_menu_about_us_label: "Lesezeichen",
  mobile_about_us_form_about_us_about_us_title_label:
    "Kopfzeile für Beschreibung",
  mobile_about_us_form_contact_info:
    "Sie können die Telefonnummer und Öffnungszeiten im Calendesk-Panel unter Einstellungen / Ihr Unternehmen festlegen. Wenn Sie die obigen Abschnitte ausblenden möchten, löschen Sie deren Inhalt.",
  mobile_about_us_form_employee_info:
    "Sie können die Mitarbeiter, die in diesem Bereich sichtbar sind, im Calendesk-Bedienfeld unter Mitarbeiter festlegen.",
  mobile_about_us_form_bookings_info:
    "In der Vorschau auf der rechten Seite sehen Sie Buchungsbeispiele.",
  mobile_about_us_form_about_us_employees_title_label:
    "Überschrift für Spezialisten",
  mobile_about_us_form_about_us_contact_title_label: "Kontaktetikett",
  mobile_about_us_form_about_us_text_label: "Text",
  mobile_about_us_form_logo_label: "Hintergrundbild",
  mobile_about_us_form_logo_select_button: "Auswählen",
  mobile_bookings_form_title: "Buchungen",
  mobile_bookings_form_menu_bookings_label: "Lesezeichen",
  mobile_bookings_form_menu_active_bookings_label: "Aktive Buchungen",
  mobile_bookings_form_menu_previous_bookings_label: "Frühere Buchungen",
  mobile_account_form_title: "Profil",
  mobile_account_form_menu_bookings_label: "Lesezeichen",
  mobile_account_editor_info_message:
    "Wählen Sie unten den Abschnitt aus, den Sie bearbeiten möchten. Sie können die mobile App für Kunden von @{google} oder @{apple} herunterladen und das Ergebnis sehen, sobald es veröffentlicht wird.",
  mobile_account_editor_info_message_2:
    "Nachdem Sie die App heruntergeladen haben, geben Sie auf dem ersten Bildschirm Ihren Firmennamen (@{company}) ein.",
  mobile_account_editor_home_image_message:
    "Die empfohlene Größe für das Hauptbild ist 600x800 Pixel.",
  mobile_account_editor_global_logo_image_message:
    "Die vorgeschlagene Logogröße ist 600x300 Pixel.",
  // Payments
  add_payment_method_dialog_subtitle:
    "Geben Sie die Daten der Karte ein, mit der Sie bei Calendesk bezahlen möchten. Calendesk speichert Ihre Kartendaten nicht. Die Daten werden sicher über das System des Zahlungsdienstleisters Stripe übertragen.",
  card_number: "Kartennummer",
  date_of_expiry: "Ablaufdatum",
  ccv: "CCV",
  card_stored_correctly: "Die Daten wurden korrekt gespeichert.",
  enter_card_data_error: "Geben Sie die richtigen Kartendaten ein.",
  // Packages and plans
  plans: "Pläne",
  activate_plan: "Plan aktivieren",
  current_plan: "Aktueller Plan",
  plan_valid_till: "gültig bis",
  plans_headline: "Flexible Preisgestaltung für Ihr Unternehmen",
  plans_headline_2:
    "Optimieren Sie Ihren Buchungsprozess und steigern Sie Ihr Geschäft mit modernen Lösungen!",
  monthly_payment: "Monatliche Zahlung",
  annual_payment: "Jährliche Zahlung",
  for_free_forever: "für immer kostenlos",
  monthly_no_vat: "monatlich",
  yearly_no_vat: "jährlich",
  once_no_vat: "einmalig",
  currently_used: "Sie verwenden jetzt",
  cancel_plan_generic_title:
    "Bevor Sie eine Entscheidung treffen, denken Sie daran, dass wir immer für Sie da sind! 💬",
  cancel_plan_generic_info:
    "Chatten Sie mit uns, um die für Sie vorteilhafteste Lösung zu finden 🤝 oder vereinbaren Sie eine kostenlose Online-Beratung mit uns.",
  cancel_plan: "Ich bestätige die Stornierung 😢",
  cancel_plan_1:
    "Ich und meine Kunden werden den Zugang zum Reservierungssystem verlieren.",
  cancel_plan_2: "Plan stornieren",
  lower_plan: "Wechseln Sie zu einem niedrigeren Tarif",
  cancel_subscription_title:
    "Sind Sie sicher, dass Sie das Abonnement stornieren möchten? 😔",
  cancel_subscription_title_2:
    "Sie nutzen derzeit eine kostenlose Testphase. Ihr Plan läuft automatisch aus, wenn Sie den Kauf nicht abschließen.",
  cancel_subscription_subtitle_2: "Nein, ich bleibe! 😊",
  cancel_subscription_subtitle_3:
    "Während des Testzeitraums können Sie Ihren Plan nicht kündigen.",
  cancel_subscription_text: "Ihr Abonnement endet am",
  cancel_subscription_text_2:
    "Alle kostenpflichtigen Funktionen stehen bis zum Ende des Abonnementzeitraums weiterhin zur Verfügung.\t",
  cancel_subscription_text_3:
    "Statt zu stornieren, wähle ich einen niedrigeren Plan.",
  cancel_subscription_text_4: "Plan @{plan} für nur @{price} (ohne MwSt.).",
  plans_advantage_1: "Unbegrenzte Anzahl von Mitarbeitern",
  plans_advantage_2: "Verwaltungs-Panel",
  plans_advantage_3: "Buchungssystem und Kalender",
  plans_advantage_4: "Unbegrenzte Anzahl von Kunden",
  plans_advantage_5: "Besuchsregister",
  plans_advantage_6: "Buchungsbezogene Ressourcen",
  plans_advantage_7: "Arbeitsplan",
  plans_advantage_8: "Online-Zahlung bei der Buchung",
  plans_advantage_9: "E-Mail Benachrichtigungen",
  plans_advantage_10: "SMS-Starterpaket - 50 Stück",
  plans_advantage_11: "SMS-Paket - 100 Nachrichten pro Monat",
  plans_advantage_12: "SMS-Paket - 200 Nachrichten pro Monat",
  plans_advantage_13: "Technische Unterstützung",
  plans_advantage_14: "Website (Unterdomäne Calendesk)",
  plans_advantage_15: "Mobile App für Mitarbeiter",
  plans_advantage_16: "Mobile App für Kunden",
  plans_advantage_17: "Unabhängige mobile App für Kunden",
  plans_advantage_18: "Persönlicher Ansprechpartner",
  plans_advantage_19: "Datenschutzpolitik und Geschäftsbedingungen",
  plans_advantage_20: "Website (eigene Domäne)",
  plans_advantage_21: "SSL-Zertifikat für die Website",
  plans_advantage_22: "1 Mitarbeiter",
  plans_advantage_23: "Bis zu 5 Mitarbeiter",
  plans_advantage_24: "Bis zu 250 Kunden",
  order_plan_summary_title: "Bestellübersicht",
  monthly_subscription: "Monatliches Abonnement",
  yearly_subscription: "Jahresabonnement",
  plan: "Plan",
  summary_payment: "Zahlung",
  summary: "Total",
  promo_code: "Rabattcode",
  promo_code_is_invalid: "Der Rabattcode ist abgelaufen oder ungültig",
  promo_code_is_valid: "Der Rabattcode wurde zu Ihrer Bestellung hinzugefügt",
  continue: "Weiter",
  continue_and_pay: "Weiterfahren und bezahlen",
  payment_method_deleted_successfully: "Die Zahlungsmethode wurde entfernt.",
  payment_method_delete_failed:
    "Die Zahlungsmethode wurde nicht entfernt, bitte erneut versuchen.",
  subscription_set_successfully: "Das Abonnement wurde korrekt eingerichtet.",
  subscription_cancelled_successfully: "Das Abonnement wurde gekündigt :(",
  // Invoices
  payment_method: "Zahlungsmethode",
  add_payment_method: "Zahlungsmethode hinzufügen",
  change_payment_method: "Zahlungsmethode ändern",
  delete_payment_method: "Löschen",
  my_invoices: "Meine Rechnungen",
  payment_history: "Zahlungsverlauf",
  download_pdf: "als PDF herunterladen",
  invoice_data_updated_successfully:
    "Die Rechnungsdaten wurden korrekt aktualisiert",
  invoice_data_update_failed:
    "Die Rechnungsdaten wurden nicht aktualisiert, bitte erneut versuchen.",
  tenant: "Unternehmen",
  created: "Bestätigung",
  deleted: "Löschen",
  updated: "Ändern",
  reminder: "Erinnerung",
  invoice_number: "Rechnungsnummer",
  invoice_issued_at: "Datum der Ausstellung",
  invoice_total: "Betrag",
  invoice_vat: "MwSt",
  invoice_description: "Beschreibung",
  buy_package_success:
    "Das Paket wurde gekauft. Die Aktivierung kann einige Sekunden dauern.",
  buy_package_failed:
    "Bei Ihrem Kauf ist ein Problem aufgetreten. Stellen Sie sicher, dass Ihre Karte funktioniert und Zahlungen akzeptiert.",
  buy_package_confirmation_button: "Paket kaufen",
  buy_package_confirmation_question:
    "Sind Sie sicher, dass Sie das Paket kaufen möchten? Ihre Karte wird belastet.",
  incorrect_time: "Falsche Zeit",
  web_editor_color_1_label: "Farbe der Genehmigungsschaltfläche",
  web_editor_color_2_label: "Filter",
  web_editor_color_3_label: "Die Farbe der Abbrechen-Schaltfläche",
  web_editor_color_4_label: "Farbe der Ladeanimation",
  web_editor_color_5_label: "Hintergrundfarbe",
  web_editor_color_6_label: "Textfarbe",
  web_editor_color_7_label: "Hellere Textfarbe",
  web_editor_color_8_label: "Hintergrundfarbe",
  web_editor_color_9_label: "Die Farbe der Links",
  web_editor_color_10_label: "Farbe der Akzente",
  web_editor_color_11_label: "Hintergrundfarbe",
  web_editor_color_12_label: "Hintergrundfarbe",
  web_editor_color_13_label: "Die Farbe der Bildlaufleiste",
  web_editor_color_14_label: "Hintergrundfarbe",
  web_editor_color_15_label: "Textfarbe",
  web_editor_color_16_label: "Hintergrundfarbe",
  web_editor_color_17_label: "Farbe der Ikonen",
  web_editor_color_18_label: "Farbe der Bildlaufleiste",
  contact_data_info:
    "Kontaktdaten erleichtern es Ihren Kunden, Ihr Unternehmen zu finden, wenn Sie beispielsweise eine mobile App verwenden.",
  service_info_7:
    "Wenn Sie die Vorauszahlungsoption aktivieren, müssen Ihre Kunden die Zahlung vornehmen, bevor die Buchung akzeptiert wird. Bis dahin wird der Kalender gesperrt, damit die Kunden ihre Zahlung abschließen können. Unbezahlte Buchungen werden zu einem Datum gelöscht, das Sie unten festlegen können. Der Kalender wird freigeschaltet, sobald unbezahlte Buchungen entfernt wurden.",
  awaiting_booking_payment_time: "Zeit für die Bezahlung der Buchung",
  service_description:
    "Eine kurze Beschreibung des Services wird angezeigt, wenn der Service ausgewählt wird.",
  company_data_saved: "Ihre Unternehmensdaten wurden korrekt gespeichert",
  company_data_failed:
    "Entschuldigung, ein Fehler ist aufgetreten. Ihre Unternehmensdaten wurden nicht gespeichert, bitte erneut versuchen.",
  closed: "Abgeschlossen",
  not_working: "Arbeitet nicht",
  generate_regulations: "Generieren",
  generate_regulations_success:
    "Geschäftsbedingungen und Datenschutzrichtlinie erfolgreich erstellt",
  generate_regulations_fail:
    "Es gab ein Problem bei der Erstellung der Allgemeinen Geschäftsbedingungen und der Datenschutzrichtlinie. Bitte erneut versuchen.",
  generate_regulations_info:
    "Generieren Sie Geschäftsbedingungen und Datenschutzrichtlinien, die auf Ihrer Calendesk-Website verwendet werden können. Diese Dokumente sind für Unternehmen erstellt, die Online-Buchungen verwalten und Zahlungen verarbeiten. Geben Sie die vollständigen Details Ihres Unternehmens ein und klicken Sie auf 'Generieren'. Fügen Sie die Dokumente Ihrer Website mit dem Builder hinzu, indem Sie Abschnitte aus der Kategorie 'Andere' auswählen. Denken Sie daran, dass die generierten Dokumente nur Muster sind und die endgültige Version mit einem Anwalt besprochen werden sollte. Calendesk ist nicht verantwortlich für den Inhalt Ihrer Dokumente.",
  regulations_and_terms_title: "Dokumentengenerator",
  full_company_name: "Vollständiger Name Ihres Unternehmens",
  full_company_name_example: "z.B. Pro&More GmbH",
  full_company_address: "Adresse der Registrierung Ihres Unternehmens",
  full_company_address_example: "z.B. Berliner Str. 1, 10101 Berlin",
  regulations_data_services_1_label:
    "Für welche Dienstleistungen erstellen Sie Vorschriften?",
  regulations_data_services_1_example:
    "z.B. juristische, psychologische, kosmetische",
  regulations_data_services_2_label: "Welche Dienstleistungen bieten Sie an?",
  regulations_data_services_2_example:
    "z.B. juristische, psychologische, kosmetische",
  regulations_data_company_url_label:
    "URL-Adresse der Website oder des Kalenders",
  regulations_data_company_email_label: "Kontakt-E-Mail für das Unternehmen",
  regulations_data_company_email_example: "z.B. kontakt@meinewebsite.de",
  regulations_data_company_phone_label: "Kontakttelefon für das Unternehmen",
  regulations_data_company_phone_example: "z.B. +493012345678",
  regulations_data_date_label: "Die Vorschriften gelten ab dem Tag",
  example_short: "z.B.",
  privacy_policy_description_too_long:
    "Der Text der Datenschutzrichtlinie ist zu lang (200.000 Zeichen).",
  regulations_description_too_long:
    "Der Text der Verordnung ist zu lang (200.000 Zeichen).",
  copy_data: "Die Daten kopieren",
  no_employees_info:
    "Sie haben noch keine Mitarbeiter? Klicken Sie unten, um Ihren ersten Mitarbeiter hinzuzufügen.",
  no_users_info:
    "Sie haben noch keine Kunden? Klicken Sie unten, um Ihren ersten Kunden hinzuzufügen.",
  no_bookings_info:
    "Sie haben noch keine Buchung? Klicken Sie unten, um Ihre erste Buchung hinzuzufügen.",
  no_services_info:
    "Noch kein Dienst? Klicken Sie unten, um Ihren ersten Dienst hinzuzufügen.",
  no_categories_info:
    "Sie haben noch keine Kategorie? Klicken Sie unten, um Ihre erste Kategorie hinzuzufügen.",
  no_newsletter_info:
    "Sie haben noch keine E-Mail-Adressen im Newsletter, klicken Sie unten, um Ihre erste E-Mail-Adresse hinzuzufügen.",
  employee_position: "Stellung",
  employee_position_example: "z. B. Manager",
  employee_note_description:
    "Hier können Sie Ihren Mitarbeiter vorstellen, diese Informationen werden auf der Website und der mobilen App angezeigt.",
  employee_permission_info:
    "Sie können Ihrem Mitarbeiter Berechtigungen erteilen und ihm erlauben, sich am System anzumelden.",
  employee_permission_employee: "Mitarbeiter (vertrauenswürdig)",
  employee_permission_employee_basic: "Mitarbeiter (eingeschränkt)",
  employee_permission_manager: "Manager",
  employee_permission_admin: "Administrator",
  employee_permission_employee_info:
    "Mitarbeiter mit dieser Berechtigung haben vollen Zugang zu den Kalendern anderer Mitarbeiter. Sie können Buchungen frei zwischen anderen Mitarbeitern austauschen. Sie haben vollen Zugriff auf alle Kundenkonten und deren Buchungen und Zahlungen. Sie haben keinen Zugang zur Verwaltung der Konten anderer Mitarbeiter. Sie können die Website und die mobile App nicht bearbeiten. Sie können die wichtigsten Systemeinstellungen nicht ändern.",
  employee_permission_employee_basic_info:
    "Mitarbeiter mit dieser Berechtigung haben nur Zugriff auf ihren eigenen Buchungskalender. Sie können Termine einsehen, hinzufügen, bearbeiten und löschen - allerdings nur im Rahmen ihres eigenen Kontos. Sie können beim Hinzufügen einer Buchung nach Kunden suchen und deren Basisdaten einsehen. Sie können die Daten der Kunden nicht löschen oder bearbeiten. Sie haben keinen Zugriff auf die Daten anderer Mitarbeiter und deren Buchungen.",
  employee_permission_manager_info:
    "Konten mit dieser Berechtigung haben Berechtigungen wie Mitarbeiter (vertrauenswürdig). Außerdem können sie andere Mitarbeiter, Dienste, Kategorien, die Website und die mobile App verwalten. Sie können den Calendesk-Plan und zusätzliche Servicepakete nicht verwalten und haben keinen Zugang zur Integration von Zahlungssystemen.",
  employee_permission_admin_info:
    "Administratorkonten haben volle Rechte auf alle Funktionen der Website.",
  choose_role: "Funktion auswählen",
  employee_app_info:
    "Ihre Mitarbeiter können ihre Kalender und Kunden in einer speziell entwickelten mobilen App anzeigen, die für iOS und Android verfügbar ist. Wählen Sie, ob Sie eine E-Mail mit einem Link zum Herunterladen der App erhalten möchten.",
  employee_public_checkbox:
    "Der Mitarbeiter ist für Kunden sichtbar und nimmt Reservierungen über den Online-Kalender an",
  employee_public_info:
    "Zusätzliche (optionale) Angaben zum Mitarbeiter können ergänzt werden. Diese Daten können später in speziellen Bereichen verwendet werden, z. B. auf der Website oder in der mobilen App.",
  employee_working_hours: "Verfügbarkeitsplan",
  employee_working_hours_info:
    "Der folgende Verfügbarkeitsplan wird für Dienste verwendet, die die Mitarbeiterverfügbarkeit nutzen.",
  color_in_calendar: "Farbe im Kalender",
  delete_success: "Erfolgreich gelöscht.",
  update_success: "Erfolgreich gespeichert.",
  web_editor_color_19_label: "Textfarbe",
  skip: "Weiter",
  configuration: "Konfiguration",
  start_configuration: "Los geht’s",
  fill: "Ausfüllen",
  filled: "Ausgefüllt",
  create: "Erstellen",
  enable: "Aktivieren",
  disable: "Deaktivieren",
  mobile_home_form_go_to_login_label: "Zur Anmeldung gehen",
  accept_terms_and_conditions_1: "Mit der Registrierung stimme ich zu",
  accept_terms_and_conditions_2: "Nutzungsbedingungen",
  accept_terms_and_conditions_3:
    "und ich bestätige, dass ich Folgendes gelesen habe",
  accept_terms_and_conditions_4: "Erklärung zum Datenschutz.",
  change_currency: "Währung ändern",
  change_currency_info: "Ändern Sie die Währungen für alle Dienstleistungen.",
  web_editor_color_20_label: "Farbe des Slogans",
  copy: "Kopieren",
  copied_to_clipboard: "In die Zwischenablage kopiert",
  stripe: "Stripe",
  stripe_description:
    "Ein professioneller Zahlungsanbieter, mit dem Sie Kundenzahlungen in Echtzeit verarbeiten können.",
  go_to_stripe: "Zu Stripe gehen",
  stripe_info_step_1:
    'Klicken Sie auf "Stripe-Konto erstellen", um zu Stripe zu gehen und Ihr Konto zu erstellen. Sobald Ihr Konto erstellt ist, wird Ihr Zahlungskonto mit Calendesk verknüpft. Calendesk hat keinen Zugriff auf Ihr Konto. Stripe ist ein unabhängiger Zahlungsanbieter. Wenn Sie Ihr Stripe-Konto mit Calendesk verknüpfen, können Sie Zahlungen in Ihrem Namen auf Ihrer Website und in Ihrer mobilen App erstellen.',
  stripe_info_step_2:
    "Gehen Sie zu Stripe, um den Prozess der Kontoerstellung abzuschließen.",
  stripe_info_step_3:
    "Die Einrichtung des Stripe-Kontos ist abgeschlossen. Die übermittelten Informationen werden von Stripe überprüft.",
  stripe_info_step_4:
    'Ihr Stripe-Konto wurde erfolgreich verbunden. Sie können jetzt Zahlungen von Ihren Kunden annehmen. Vergessen Sie nicht, die Option "Vorauszahlung" anzukreuzen - für alle vorauszahlungspflichtigen Dienstleistungen.',
  stripe_title_step_1: "Stripe-Konto erstellen",
  stripe_title_step_2: "Kontoerstellung im Gange",
  stripe_title_step_3: "Kontoverifizierung",
  stripe_title_step_4: "Konto verbunden",
  allow_online_payments: "Zahlungen",
  allow_online_payment_2: "Aktivieren Sie Online-Zahlungen für diesen Dienst.",
  payment_required: "Vorauszahlung",
  payment_required_2:
    "Vor der Bestätigung Ihrer Buchung ist eine Vorauszahlung erforderlich.",
  service_payments: "Zahlungseinstellungen",
  paid_online: "Zahlung",
  paid: "Bezahlt",
  not_paid: "Unbezahlt",
  navbar_element_form_home_label: "Startseite",
  user_payments: "Zahlungen",
  past_due_subscription_title: "Ihr Konto wurde vorübergehend gesperrt 😵",
  past_due_subscription_subtitle_1:
    "Es tut uns leid, Ihnen mitteilen zu müssen, dass Ihr Konto bei Calendesk aufgrund von Zahlungsrückständen vorübergehend gesperrt wurde. Wir haben mehrmals versucht, Gelder von Ihrer Karte abzubuchen, jedoch ohne Erfolg. Daher empfehlen wir Ihnen, Ihre Karte zu überprüfen und einen erneuten Zahlungsversuch zu unternehmen.",
  past_due_subscription_subtitle_2:
    "Wenn Sie Ihre Karte wechseln möchten, können Sie das unten tun. Nachdem die Zahlung durchgeführt wurde, wird Ihr Konto automatisch entsperrt. Wenn Sie irgendwelche Fragen oder Bedenken haben, zögern Sie nicht, uns über den Chat zu kontaktieren. Wir werden gerne Ihre Bedenken ausräumen und Ihnen bei der Lösung von Zahlungsproblemen helfen.",
  past_due_subscription_subtitle_3:
    "Es tut uns leid, Ihnen mitteilen zu müssen, dass Ihr Konto bei Calendesk aufgrund von Zahlungsrückständen vorübergehend gesperrt wurde. Bitte setzen Sie sich dringend mit dem Kontoadministrator oder der für Zahlungen verantwortlichen Person in Verbindung.",
  retry_past_due_invoice_title: "Nochmal bezahlen",
  past_due_subscription_title_2:
    "Es gibt eine ausstehende Zahlung auf Ihrem Konto",
  past_due_subscription_subtitle_4:
    "Bitte leisten Sie die Zahlung, um eine automatische Kontosperrung zu vermeiden.",
  past_due_subscription_subtitle_5:
    "Solange es unbezahlte Zahlungen auf Ihrem Konto gibt, sind Änderungen oder Stornierungen von Abonnements nicht möglich.",
  past_due_subscription_subtitle_6:
    "Wenn Sie die Karte ersetzen möchten, können Sie dies unten tun. Bei Fragen oder Zweifeln können Sie uns gerne über den Chat kontaktieren. Wir werden Ihre Zweifel gerne ausräumen und Ihnen helfen, das Zahlungsproblem zu lösen.",
  select_plan: "Wählen Sie einen Plan",
  subscription_will_not_renew_info:
    "Ihr Plan wird sich nicht automatisch erneuern",
  no_email_for_password_reset_info:
    "Sie können kein Passwort festlegen, wenn der ausgewählte Benutzer keine E-Mail-Adresse angegeben hat.",
};
